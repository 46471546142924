import React, { useContext } from 'react';
import { Place, PhotoCamera, Home, DirectionsRun, Person } from '@material-ui/icons';
import { isPaid, isStripe } from 'lib/utils';
import { AppContext } from 'context';

// const { currentUser } = useContext(AppContext);

export const MENU_ITEMS = {
	loggedIn: [
		{ value: '/home', text: 'Feed', icon: Home },
		{
			value: '/courses',
			text: 'Training',
			icon: DirectionsRun
		},
		{
			value: '/videos/upload',
			text: 'Capture',
			icon: PhotoCamera
		},
		{
			value: '/users/me',
			text: 'Profile',
			icon: Person
		}
	],
	loggedOut: [
		{ value: '/login', text: 'Sign In' },
		{
			value: '/forgot_password',
			text: 'Reset password'
		}
	]
};

export const SETTING_ITEMS = [
	// {
	// 	value: '/users/subscription',
	// 	label: 'Your Subscription',
	// },

	{ value: '/users/PremiumSignup', label: 'Your Subscription' },
	{ value: 'contact_us', label: 'Contact Us' },
	{ value: '/terms', label: 'Terms and Conditions' },
	{ value: 'logout', label: 'Log Out' }
];
// isPaid(currentUser) ?'/users/StripeSubscription':
