import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		border: `1px solid ${theme.palette.background.referral}35`,
		borderRadius: 6
	},
	label: {
		minWidth: 160,
		paddingLeft: theme.spacing(1.5)
	},
	inputBase: {
		height: 44,
		width: '100%',
		'& input, & textarea': {
			webkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
			padding: theme.spacing(1, 1.5),
			minHeight: 28,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 15,
			border: `1px solid ${theme.palette.background.referral}35`,
			'&::placeholder': {
				color: theme.palette.primary.light
			},
			borderRadius: 6
		}
	},
	uppercase: {
		'& input, & textarea': {
			textTransform: 'uppercase'
		}
	},
	IconButton: {
		padding: '5px'
	},
	icon: {
		fontSize: '20px',
		color: '#888'
	}
}));
