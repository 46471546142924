import {
	proximanovaMedium,
	proximanovaRegular,
	proximanovaSemibold,
	tungstenBold,
	tungstenSemibold
} from 'theme/fonts';

export default {
	'@global': {
		'@font-face': [proximanovaMedium, proximanovaRegular, proximanovaSemibold, tungstenBold, tungstenSemibold]
	}
};
