import { Button, Typography, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';

import AppleIcon from 'assets/icons/apple.svg';
import GooglePlayIcon from 'assets/icons/google-play.svg';
import palette from 'theme/palette';

const DownloadAppButton = ({ variant }) => {
	const classes = useStyles();
	const url =
		variant === 'apple'
			? 'https://apps.apple.com/gb/app/ellevate-football/id1386788711'
			: 'https://play.google.com/store/apps/details?id=com.ArmBoldMind.Ellevate';
	const Icon = variant === 'apple' ? AppleIcon : GooglePlayIcon;
	const Text1 = variant === 'apple' ? 'Download on the' : 'Get it on';
	const Text2 = variant === 'apple' ? 'App Store' : 'Google Play';

	return (
		<Button size="medium" onClick={() => window.open(url)} variant="contained" className={classes.button}>
			<img src={Icon} className={classes.icon} alt="App Icon" />
			<div className={classes.textContainer}>
				<StyledTypography variant="caption">{Text1}</StyledTypography>
				<BlackTextTypography variant="body1">{Text2}</BlackTextTypography>
			</div>
		</Button>
	);
};

const useStyles = makeStyles((theme) => ({
	button: {
		height: 64,
		backgroundColor: palette.background.referral,
		borderRadius: 14,
		flexDirection: 'column',
		textTransform: 'none',
		flexGrow: 1,
		width: '40%',
		[theme.breakpoints.down('sm')]: {
			padding: 4
		}
	},
	textContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		marginLeft: 10,
		gap: '5px'
	}
}));

const StyledTypography = withStyles((theme) => ({
	root: {
		marginBottom: -10,
		fontSize: 12,
		fontWeight: 400,
		marginLeft: 2,
		[theme.breakpoints.down('xs')]: {
			fontSize: '60%'
		}
	}
}))(Typography);

const BlackTextTypography = withStyles((theme) => ({
	root: {
		color: '#000000',
		fontSize: 20,
		fontWeight: 800,
		[theme.breakpoints.down('xs')]: {
			fontSize: '80%'
		}
	}
}))(Typography);

export default DownloadAppButton;
