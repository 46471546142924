import React from 'react';
import { Container, Typography, withWidth, makeStyles } from '@material-ui/core';

const Subheader = ({ title, ...props }) => {
	const classes = useStyles();

	return (
		<Container>
			<div className={classes.root}>
				<Typography color="textPrimary" variant="h4" className={classes.title}>
					{title}
				</Typography>
			</div>
		</Container>
	);
};
export default Subheader;

const useStyles = makeStyles((theme) => ({
	title: {
		paddingTop: theme.spacing(12),
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(0),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(2)
		}
	}
}));
