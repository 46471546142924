import React from 'react';
import { useResponsive } from 'hooks';
import { makeStyles } from '@material-ui/core';
import { COVER_HEIGHT, VIDEO_MOBILE_HEIGHT } from 'lib/constants';

const VideoBackground = ({ mobileVideoUrl, desktopVideoUrl, ...props }) => {
	const classes = useStyles();
	const { isMobile } = useResponsive();
	const src = isMobile ? mobileVideoUrl : desktopVideoUrl;

	return (
		<div className={classes.root}>
			<video
				autoPlay
				loop
				playsInline={true}
				muted
				controls={false}
				height="auto"
				width="100%"
				className={classes.video}
			>
				<source src={src} type="video/mp4" />
			</video>
		</div>
	);
};

export default VideoBackground;

const useStyles = makeStyles((theme) => ({
	root: {
		overflow: 'hidden',
		height: COVER_HEIGHT,
		[theme.breakpoints.down('sm')]: {
			height: VIDEO_MOBILE_HEIGHT
		}
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 999,
		width: '100%',
		height: 'auto',
		position: 'absolute'
	},
	icon: {
		height: 60,
		width: 60,
		color: theme.palette.common.white
	}
}));
