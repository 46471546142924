import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { syntheticEvent } from 'lib/utils';
import { Lens, LensOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const RatingInput = ({ value, label, name, handleChange, max = 5, size, ...rest }) => {
	const handleRatingChange = (event, newValue) => {
		let ev = syntheticEvent(newValue, name);
		handleChange(ev);
	};

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.label} component="legend">
				{label}
			</Typography>
			<StyledRating
				name={name}
				value={parseInt(value || 0)}
				onChange={handleRatingChange}
				max={max}
				size={size}
				icon={<Lens fontSize="inherit" />}
				emptyIcon={<LensOutlined fontSize="inherit" />}
			/>
		</div>
	);
};

export default RatingInput;

const StyledRating = withStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main
	},
	iconFilled: {
		color: theme.palette.primary.main
	},
	iconHover: {
		color: theme.palette.primary.main
	}
}))(Rating);

const useStyles = makeStyles((theme) => ({
	label: {
		width: 120,
		fontSize: 20,
		borderRadius: 20,
		fontFamily: theme.typography.subtitle1.fontFamily
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center'
	}
}));
