import React, { useState } from 'react';
import { FormControl, Typography, InputBase } from '@material-ui/core';
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import withStyles from 'hoc/withStyles';

const MaskInput = ({
	className,
	mask = '',
	required,
	label,
	name,
	value,
	border = false,
	handleChange,
	placeholder,
	onKeyPress,
	autoComplete = 'off',
	borderBottom = false,
	error = false,
	classes,
	...props
}) => {
	return (
		<FormControl fullWidth className={clsx(className, classes.root)}>
			{label && (
				<div className={classes.label}>
					<Typography variant="body1">{label}</Typography>
				</div>
			)}
			<InputMask
				mask={mask}
				value={value}
				onChange={handleChange}
				maskPlaceholder={'_'}
				disabled={false}
				maskChar=" "
			>
				{() => (
					<InputBase
						fullWidth
						error={error}
						className={clsx(classes.inputBase)}
						autoComplete="off"
						type="text"
						name={name}
						placeholder={placeholder}
						onKeyPress={onKeyPress}
						variant="outlined"
					/>
				)}
			</InputMask>
		</FormControl>
	);
};
MaskInput.displayName = 'MaskInput';
export default withStyles(MaskInput);
