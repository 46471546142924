import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';
import { Hidden, Breadcrumbs, Link, Typography } from '@material-ui/core';

const Breadcrumb = ({ ...props }) => {
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const location = useLocation();
	const { pathname } = location;

	let id;
	let courseId;
	let lessonId;

	const handleBreadcrumbs = () => {
		const url = pathname.split('/');
		const segments = [];

		// Home
		if (
			url.includes('home') ||
			url.includes('articles') ||
			url.includes('benefits') ||
			url.includes('topics') ||
			url.includes('editorials') ||
			url.includes('events')
		) {
			segments.push({
				label: 'Feed',
				value: `/home`
			});
		}

		// Clubs
		if (url.includes('clubs')) {
			let clubId = url[url.indexOf('clubs') + 1];
			segments.push({
				label: 'CLUBS',
				value: `/clubs`
			});
			if (clubId) {
				segments.push({
					label: 'DETAILS',
					value: `/clubs/${clubId}`
				});
			}
		}

		// Training
		if (url.includes('courses') || url.includes('lessons')) {
			segments.push({
				label: 'Training',
				value: `/courses`
			});
		}

		if (url.includes('courses')) {
			courseId = url[url.indexOf('courses') + 1];
			if (courseId) {
				segments.push({
					label: 'Course',
					value: `/courses/${courseId}`
				});
			}
		}

		if (url.includes('search')) {
			segments.push({
				label: 'Training',
				value: `/courses`
			});
			segments.push({
				label: 'Search',
				value: `/search`
			});
		}

		if (url.includes('lessons')) {
			lessonId = url[url.indexOf('lessons') + 1];
			segments.push({
				label: 'Lesson',
				value: `/courses/${courseId}/lessons/${lessonId}`
			});
		}

		// Editorial
		if (url.includes('topics')) {
			id = url[url.indexOf('topics') + 1];
			segments.push({
				label: 'Editorial Videos',
				value: `/topics`
			});
			if (id) {
				segments.push({
					label: 'Browse',
					value: `/topics/${id}`
				});
			}
		}

		// Benefits
		if (url.includes('benefits')) {
			id = url[url.indexOf('benefits') + 1];
			segments.push({
				label: 'Benefits',
				value: `/benefits`
			});
			if (id) {
				segments.push({
					label: 'Details',
					value: `/benefits/${id}`
				});
			}
		}

		// Events
		if (url.includes('events')) {
			id = url[url.indexOf('events') + 1];
			segments.push({
				label: 'Events',
				value: `/events`
			});
			if (id) {
				segments.push({
					label: 'Details',
					value: `/events/${id}`
				});
			}
		}

		// Articles
		if (url.includes('articles')) {
			id = url[url.indexOf('articles') + 1];
			segments.push({
				label: 'The Boot Room',
				value: `/articles`
			});
			if (id) {
				segments.push({
					label: 'Details',
					value: `/articles/${id}`
				});
			}
		}

		// Profile
		if (url.includes('users')) {
			segments.push({
				label: 'Profile',
				value: `/users/me`
			});
		}

		setBreadcrumbs(segments);
	};

	useEffect(() => {
		handleBreadcrumbs();
	}, [location?.pathname]);

	return (
		<Hidden smDown>
			<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
				{breadcrumbs.map((item, i) => (
					<Link underline="hover" color="inherit" href={item.value} key={i}>
						{item.label.toUpperCase()}
					</Link>
				))}
			</Breadcrumbs>
		</Hidden>
	);
};

export default Breadcrumb;
