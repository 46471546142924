import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Box, IconButton, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { useResponsive } from 'hooks';
import { Clear } from '@material-ui/icons';
import clsx from 'clsx';

const VideoUploader = ({ circle = false, handleUpload, label = 'Upload video', videoUrl, handleDelete, ...props }) => {
	const classes = useStyles();
	const [isEditing, setIsEditing] = useState(false);
	const [file, setFile] = useState();
	const [files, setFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isUploaded, setIsUploaded] = useState(false);
	const [dropzoneActive, setDropzoneActive] = useState(false);

	const { isMobile } = useResponsive();

	const onDrop = async (files) => {
		setIsUploading(true);
		setIsUploaded(false);
		await handleUpload(files[0]);
		setIsEditing(false);
		setIsUploading(false);
		setIsUploaded(true);
		setFiles([]);
		setFile(null);
	};

	const onDragEnter = (files) => {
		setFiles(files);
		setDropzoneActive(true);
	};

	const onDragLeave = (files) => {
		setFiles(files);
		setDropzoneActive(false);
	};

	return (
		<div>
			{videoUrl && handleDelete && (
				<div className={classes.deleteButton}>
					<IconButton className={classes.button} color="primary" onClick={handleDelete}>
						<Clear />
					</IconButton>
				</div>
			)}

			{videoUrl && (
				<div className={classes.videoPoster}>
					{isMobile ? (
						<center>
							<Typography variant="body1">FILE LOADED.</Typography>
							<Typography variant="body1">TO REPLACE, DRAG AND DROP NEW MEDIA</Typography>
							<Typography variant="body1">OR CLICK TO UPLOAD</Typography>
						</center>
					) : (
						<video src={videoUrl} />
					)}
				</div>
			)}

			{(isEditing || !videoUrl) && (
				<Dropzone
					className={classes.dropZone}
					disableClick={false}
					accept="video/*"
					onDrop={onDrop}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					multiple={false}
				>
					{!isUploaded && !isUploading && !dropzoneActive && (
						<div>
							<Typography variant="body1">DRAG AND DROP MEDIA</Typography>
							<Typography variant="body1">OR CLICK TO UPLOAD</Typography>
						</div>
					)}

					{isUploaded && (
						<div>
							<Typography variant="body1">DRAG AND DROP MEDIA</Typography>
							<Typography variant="body1">OR CLICK TO UPLOAD</Typography>
						</div>
					)}

					{!isUploaded && !isUploading && dropzoneActive && (
						<Typography variant="body1">DROP VIDEO TO UPLOAD</Typography>
					)}

					{!isUploaded && isUploading && <CircularProgress color="primary" />}
				</Dropzone>
			)}
		</div>
	);
};

export default VideoUploader;

const useStyles = makeStyles((theme) => ({
	image: {
		padding: 2,
		borderRadius: 4,
		marginRight: theme.spacing(1),
		height: (props) => props.size,
		width: (props) => props.size
	},
	circle: {
		borderRadius: 100
	},
	dropZone: {
		margin: 0,
		height: 420,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		border: `1px solid ${theme.palette.text.secondary}`,
		backgroundColor: theme.palette.background.light,
		textAlign: 'center',
		marginBottom: theme.spacing(2)
	},
	button: {
		zIndex: theme.zIndex.modal + 1
	},
	deleteButton: {
		width: '100%',
		display: 'flex',
		marginBottom: -50,
		justifyContent: 'flex-end'
	},
	videoPoster: {
		width: '100%',
		maxWidth: 'calc(100vw - 20px)',
		height: 420,
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		border: `1px solid ${theme.palette.text.secondary}`,
		backgroundColor: theme.palette.background.light
	}
}));
