import React from 'react';
import { Autosuggest, DateInput, TextInput } from 'components';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { COUNTRIES } from 'lib/constants/countries';
import palette from 'theme/palette';

const ReferralSignupForm = ({ user, handleChange, handleSubmit }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					border
					name="email"
					value={user.email}
					placeholder="EMAIL"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="first_name"
					value={user.first_name}
					placeholder="FIRST NAME"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="last_name"
					value={user.last_name}
					placeholder="LAST NAME"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<DateInput
					name="dob"
					value={user.dob}
					placeholder="DD/MM/YYYY"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={COUNTRIES}
					value={user.country}
					name="country"
					placeholder="COUNTRY/REGION"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="password"
					value={user.password}
					type="password"
					placeholder="PASSWORD"
					variant="referral"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Box my={4} />
				<Button
					fullWidth
					size="large"
					color="primary"
					onClick={handleSubmit}
					variant="contained"
					style={{
						height: 64,
						backgroundColor: palette.background.referral,
						borderRadius: 14
					}}
				>
					Continue
				</Button>
				<Box my={4} />
				<center>
					<Typography variant="body1">Find out more about us</Typography>
					<Typography variant="body1">
						<Link
							color="secondary"
							href="https://www.ellevatefootball.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							VISIT WEBSITE
						</Link>
					</Typography>
				</center>
			</Grid>
		</Grid>
	);
};

export default ReferralSignupForm;
