import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Image from 'assets/images/login-background.png';
import CustomModal from './CustomModal';

const AuthScreen = ({ title, children, handleClosePopUp, open, alertMessage }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container maxWidth="xs">
				<Box>
					<CustomModal
						title={'Promotion code'}
						msg={alertMessage}
						buttonLabel={'Continue to the app'}
						handleClose={handleClosePopUp}
						open={open}
					/>
					{children}
				</Box>
			</Container>
		</div>
	);
};

export default AuthScreen;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 40,
		left: 0,
		height: '100vh',
		width: '100vw',
		background:
			'linear-gradient(11deg, rgba(0, 0, 0, 0.30) 65.39%, rgba(107, 224, 160, 0.30) 98.36%), var(--paper, #000)',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));
