import { Box, Button, Grid } from '@material-ui/core';
import { Autosuggest, DateInput, TextInput } from 'components';
import { COUNTRIES } from 'lib/constants/countries';
import React from 'react';

const SignupForm = ({ user, handleChange, handleSubmit }) => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<TextInput border name="email" value={user.email} placeholder="EMAIL" handleChange={handleChange} />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="first_name"
					value={user.first_name}
					placeholder="FIRST NAME"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="last_name"
					value={user.last_name}
					placeholder="LAST NAME"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<DateInput name="dob" value={user.dob} placeholder="DD/MM/YYYY" handleChange={handleChange} />
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={COUNTRIES}
					value={user.country}
					name="country"
					placeholder="COUNTRY/REGION"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="password"
					value={user.password}
					type="password"
					placeholder="PASSWORD"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<center>
					<Box width={244} my={2}>
						<Button fullWidth size="large" color="primary" onClick={handleSubmit} variant="contained">
							Next
						</Button>
					</Box>
				</center>
			</Grid>
		</Grid>
	);
};

export default SignupForm;
