// https://cloudinary.com/documentation/resizing_and_cropping
export const resize = (src, { width, height, transform = 'fill' }) => {
	if (!src) {
		return;
	}
	let index = src.indexOf('/upload') + 7; // 7 is number of chars in '/upload'
	let params = [`/c_${transform}`];
	if (width && width > 0) params.push(`w_${width}`);
	if (height && height > 0) params.push(`h_${height}`);
	let transformedUrl = src.substring(0, index) + params.join(',') + src.substring(index);
	return transformedUrl;
};

export const cover = (src, { width = 1440, height = 720, gravity = 'north' }) => {
	if (!src) return null;
	let index = src.indexOf('/upload') + 7; // 7 is number of chars in '/upload'
	//let params = `/c_scale,w_${width}/c_crop,w_${width},h_${height}`
	let params = `/c_fill,h_${height},w_${width},g_${gravity}`;
	let transformedUrl = src.substring(0, index) + params + src.substring(index);
	return transformedUrl;
};
