import React, { useEffect, useState } from 'react';
import { Typography, InputBase, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from 'hoc/withStyles';

const Autosuggest = ({
	value,
	options,
	label,
	name,
	placeholder = 'Select',
	multiselect = false,
	handleChange,
	handleInputChange,
	classes,
	...props
}) => {
	const [selected, setSelected] = useState({
		label: '',
		value: null
	});

	const handleOnChange = (event, newValue) => {
		setSelected(newValue);
		handleChange({
			target: {
				name: name,
				value: newValue?.value
			}
		});
	};

	useEffect(() => {
		if (typeof value != 'object') {
			setSelected(options.find((o) => o.value == value));
		} else {
			setSelected(value);
		}
	}, [value]);

	return (
		<div className={classes.root}>
			{label && (
				<div className={classes.label}>
					<Typography variant="body1">{label}</Typography>
				</div>
			)}
			<Autocomplete
				multiple={multiselect}
				disableCloseOnSelect={multiselect}
				classes={{
					paper: classes.paper,
					option: classes.option,
					popperDisablePortal: classes.popperDisablePortal
				}}
				value={selected}
				onChange={(event, newValue) => {
					handleOnChange(event, newValue);
				}}
				onInputChange={(event, newInputValue) => {
					handleInputChange && handleInputChange(newInputValue);
				}}
				noOptionsText="No options"
				clearOnBlur
				handleHomeEndKeys
				options={options}
				getOptionLabel={(option) => option?.label}
				getOptionSelected={(option, value) => option?.label == value?.label}
				renderInput={(params) => (
					<InputBase
						placeholder={placeholder}
						ref={params.InputProps.ref}
						inputProps={{
							...params.inputProps,
							autoComplete: 'none'
						}}
						className={classes.inputBase}
					/>
				)}
			/>
		</div>
	);
};

Autosuggest.displayName = 'Autosuggest';

export default withStyles(Autosuggest);
