import React from 'react';
import { Typography, withWidth, makeStyles } from '@material-ui/core';

const PageHeader = ({ title, subtitle, ...props }) => {
	const classes = useStyles();

	return (
		<center className={classes.root}>
			<Typography color="textPrimary" variant="h3" className={classes.title}>
				{title}
			</Typography>
			<Typography color="textPrimary" variant="subtitle1" className={classes.subtitle}>
				{subtitle}
			</Typography>
		</center>
	);
};
export default PageHeader;

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(3)
	},
	title: {
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(0)
	},
	subtitle: {}
}));
