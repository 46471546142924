import { isEmail, isEmpty, isMinLength, isWorkEmail } from 'validations';

export const validateUser = (user) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(user.first_name) || isEmpty(user.last_name)) {
		isValid = false;
		messages.push('Full name is required');
	}

	if (isEmpty(user.country)) {
		isValid = false;
		messages.push('Country is required');
	}

	if (isEmpty(user.dob)) {
		isValid = false;
		messages.push('Date of birth is required');
	}

	if (isEmpty(user.email)) {
		isValid = false;
		messages.push('Email is required');
	}

	if (isEmpty(user.password)) {
		isValid = false;
		messages.push('Password is required');
	}

	if (!isMinLength(user.email, 4)) {
		isValid = false;
		messages.push('Email must be at least 4 characters');
	}

	if (!isMinLength(user.password, 4)) {
		isValid = false;
		messages.push('Password must be at least 4 characters');
	}

	if (!isEmail(user.email)) {
		isValid = false;
		messages.push('Email is not a valid email');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};

export const validatePassword = (password, passwordConfirmation) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(password) || isEmpty(passwordConfirmation)) {
		isValid = false;
		messages.push('Password and password confirmation are required');
	}

	if (!isMinLength(password, 4)) {
		isValid = false;
		messages.push('Password must be at least 4 characters');
	}

	if (password != passwordConfirmation) {
		isValid = false;
		messages.push('Passwords do not match');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};

export const validateParent = (user) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(user.first_name) || isEmpty(user.last_name)) {
		isValid = false;
		messages.push('Full name is required');
	}

	if (isEmpty(user.email)) {
		isValid = false;
		messages.push('Email is required');
	}

	if (!isMinLength(user.email, 4)) {
		isValid = false;
		messages.push('Email must be at least 4 characters');
	}

	if (!isEmail(user.email)) {
		isValid = false;
		messages.push('Email is not a valid email');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};

export const validateNicePayUser = (user) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(user.buyer_name)) {
		isValid = false;
		messages.push('Full name is required');
	}

	if (isEmpty(user.buyer_email)) {
		isValid = false;
		messages.push('Email is required');
	}

	if (!isMinLength(user.buyer_email, 4)) {
		isValid = false;
		messages.push('Email must be at least 4 characters');
	}

	if (!isEmail(user.buyer_email)) {
		isValid = false;
		messages.push('Email is not a valid email');
	}
	if (isEmpty(user.buyer_country)) {
		isValid = false;
		messages.push('Country is required');
	}
	if (isEmpty(user.buyer_city)) {
		isValid = false;
		messages.push('City is required');
	}
	if (isEmpty(user.buyer_state)) {
		isValid = false;
		messages.push('State is required');
	}
	if (isEmpty(user.buyer_address)) {
		isValid = false;
		messages.push('Address is required');
	}
	if (isEmpty(user.buyer_postal_code)) {
		isValid = false;
		messages.push('Postal code is required');
	}
	if (!new RegExp('[0-9]').test(user.buyer_postal_code)) {
		isValid = false;
		messages.push('Please enter a valid number');
	}

	if (!new RegExp('[0-9]').test(user.buyer_phone)) {
		isValid = false;
		messages.push('Please enter a valid phone number');
	}
	if (isEmpty(user.buyer_phone)) {
		isValid = false;
		messages.push('Phone is required');
	}
	let validation = {
		isValid,
		messages
	};

	return validation;
};
