export const isStripe = (user) => (user?.stripe_customer_id ? true : false);
export const isPaid = (user) => (user?.paid ? true : false);

export const isLessonCompleted = (user, lessonId) => {
	return user?.completed_lesson_ids?.includes(lessonId);
};

export const capitalize = (string) => {
	if (!string) {
		return;
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const pluralize = (string) => {
	if (!string) {
		return;
	}
	const regex = /$s/;
	if (!string.match(regex)) {
		string = string + 's';
	}
	return string;
};

export const truncate = (text, len) => {
	if (!text) {
		return;
	}
	let truncated = text.slice(0, len);
	if (text.length > len) {
		truncated += '...';
	}
	return truncated;
};

export const buildOptions = (items, key, value) => {
	if (!items) return null;
	let options = [];
	let list = [];
	if (items.data) {
		list = items.data;
	} else {
		list = items;
	}
	list.forEach((item, idx) => {
		let label = item[value];
		if (Array.isArray(value)) {
			label = value.map((v) => eval(`item.${v}`)).join(' - ');
		}
		return options.push({
			value: item[key],
			label: label
		});
	});
	return options;
};

export const syntheticEvent = (value, name, type = 'text') => {
	let ev = {
		target: {
			value,
			name,
			type
		}
	};
	return ev;
};

export const monthsNumber = {
	[1]: 'January',
	[2]: 'February',
	[3]: 'March',
	[4]: 'April',
	[5]: 'May',
	[6]: 'June',
	[7]: 'July',
	[8]: 'August',
	[9]: 'September',
	[10]: 'October',
	[11]: 'November',
	[12]: 'December'
};

export const hasComponentName = (str) => {
	if (str.length >= 3) {
		return true;
	} else {
		return false;
	}
};
