import { Button, Hidden, makeStyles } from '@material-ui/core';
import { Center } from 'components';
import { AppContext } from 'context';
import { useAlerts, useAuth, usePaywall, useUsers } from 'hooks';
import { isPaid, isStripe } from 'lib/utils';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import PromotionCodeModal from './promotionCodeModal';
import SelectPaymentMethodModal from './selectPaymentMethodModal';

const StripeCreditCard = ({ onSubscribed, selectedPackage }) => {
	const classes = useStyles();
	const history = useNavigate();
	const { fetchMe } = useAuth();
	const { currentUser } = useContext(AppContext);
	const { showAlertSuccess, showAlertError } = useAlerts();
	const [openPromotionModal, setOpenPromotionModal] = useState(false);
	const [openSelectPaymentModal, setSelectPaymentModal] = useState(false);

	const { subscribe } = useUsers();
	const { AddAselectedPlan } = usePaywall();

	const handleSubscribe = async () => {
		if (isStripe(currentUser)) {
			await subscribe(selectedPackage);
			await fetchMe();
			showAlertSuccess('You have successfully subscribed');
			if (onSubscribed) {
				onSubscribed();
			}
		} else {
			showAlertError('Please enter your credit card details.');
		}
	};

	const handlePayNow = () => {
		switch (currentUser.country) {
			case 'ID':
				history.push('/users/nicepay-subscription');
				break;
			default:
				history.push('/users/stripe-subscription');
		}
	};

	const handleManageSubscription = async () => {
		if (currentUser.country == 'ID') {
			AddAselectedPlan(selectedPackage);
			history.push('/users/NicePaySubscription');
		}
		history.push('/users/StripeSubscription');
	};

	return (
		<div>
			<PromotionCodeModal open={openPromotionModal} handleClose={() => setOpenPromotionModal(false)} />
			<SelectPaymentMethodModal open={openSelectPaymentModal} handleClose={() => setSelectPaymentModal(false)} />
			{/* {!isPaid(currentUser) && (
        <div className={classes.promoCodeLink}>
          <Center>
            <Button
              onClick={() => setOpenPromotionModal(true)}
              disableRipple
              className={classes.promoCodeLink}
            >
              + Add a promotion code
            </Button>
          </Center>
        </div>
      )} */}
			{currentUser && (
				<div>
					<div>
						{isPaid(currentUser) && isStripe(currentUser) && (
							<Button
								fullWidth
								className={classes.button}
								color="primary"
								onClick={handleManageSubscription}
								variant="contained"
							>
								MANAGE <Hidden smDown>CREDIT CARDS</Hidden>
							</Button>
						)}
					</div>

					<div>
						{!isPaid(currentUser) && isStripe(currentUser) ? (
							<Button
								fullWidth
								className={classes.button}
								color="primary"
								onClick={handleSubscribe}
								variant="contained"
							>
								SUBSCRIBE
							</Button>
						) : (
							<Button
								fullWidth
								className={classes.button}
								color="primary"
								onClick={handlePayNow}
								variant="contained"
							>
								PAY NOW
							</Button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default StripeCreditCard;

const useStyles = makeStyles((theme) => ({
	button: {
		marginTop: theme.spacing(1),
		textTransform: 'none',
		backgroundColor: 'white'
	},
	promoCodeLink: {
		fontSize: 12,
		color: 'grey',
		textDecoration: 'underline grey'
	}
}));
