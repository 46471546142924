import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
const VideoPlayer = ({ src, videoId, captions, autoPlay = true, onComplete, ...props }) => {
	const classes = useStyles();
	const [completed, setCompleted] = useState(false);
	const [playlist, setPlaylist] = useState();

	const handleBuildPlaylist = () => {
		let tracks = [];
		let video = src;
		// if (videoId) {
		// 	video = `https://content.jwplatform.com/videos/${videoId}.mp4`
		// }

		if (videoId && captions && Object.keys(captions).length > 0) {
			Object.keys(captions).forEach((key) => {
				console.log(key);
				let caption = {
					label: key,
					// kind: 'captions',
					kind: 'subtitles',
					src: captions[key]
				};
				tracks.push(caption);
			});
		}

		setPlaylist({
			file: video,
			tracks: tracks
		});
	};

	const handleOnTimeEvent = async (playerProgress) => {
		const { duration, currentTime } = playerProgress;
		if (!completed && currentTime / duration > 0.99) {
			setCompleted(true);
			if (onComplete) {
				onComplete();
			}
		}
	};

	useEffect(() => {
		setCompleted(false);
		handleBuildPlaylist();
	}, []);

	return (
		<div>
			{playlist &&
				(console.log(playlist.file),
				(
					<center>
						<ReactPlayer
							playing
							width={'100%'}
							height={'100%'}
							url={playlist.file}
							controls={true}
							config={{
								file: {
									tracks: playlist.tracks
								},
								attributes: {
									controlsList: 'nodownload',
									crossOrigin: 'anonymous'
								}
							}}
						/>

						{/* <ReactJWPlayer
						playerId="12345"
						playlist={playlist}
						isAutoPlay={autoPlay}
						onTime={handleOnTimeEvent}
					/> */}
					</center>
				))}
		</div>
	);
};

export default VideoPlayer;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0
	},
	video: {
		position: 'relative',
		paddingTop: '56.25% '
	}
}));
