import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const Gradient = ({ height = 120, ...props }) => {
	const classes = useStyles({ height });

	return <div className={classes.root}></div>;
};

Gradient.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object
};

export default Gradient;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: (props) => props.height,
		background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0))'
	}
}));
