import moment from 'moment';
import queryString from 'query-string';
import React, { useState } from 'react';

import { ReferralScreen } from 'components';
import ReferralSignupForm from 'features/auth/ReferralSignupForm';
import { useAlerts, useAuth } from 'hooks';
import { validateUser } from 'validations/users';
import SignupSuccess from '../auth/SignupSuccess';
import { useSearchParams } from 'react-router-dom';

const SignupWithReferral = ({ ...props }) => {
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const { user, handleChange, signupUser, authenticateFromToken } = useAuth();
	const { showAlertError } = useAlerts();
	const [signUpCompleted, setSignUpCompleted] = useState(false);

	const handleSubmit = async () => {
		const valid = validateUser(user);
		if (valid.isValid) {
			const resp = await signupUser({
				...user,
				dob: moment(user.dob, 'DD/MM/YYYY'),
				referral_code: code
			});
			if (resp?.id && resp.promo_code_message) {
				authenticateFromToken(resp.token);
				setSignUpCompleted(true);
			} else if (resp?.id) {
				authenticateFromToken(resp.token);
				setSignUpCompleted(true);
			}
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit();
	};

	return (
		<div>
			<ReferralScreen title="Referral" showTitle={signUpCompleted === false}>
				{signUpCompleted ? (
					<SignupSuccess title={'You have successfully claimed your free trial!'} />
				) : (
					<ReferralSignupForm
						user={user}
						handleChange={handleChange}
						handleKeyPress={handleKeyPress}
						handleSubmit={handleSubmit}
					/>
				)}
			</ReferralScreen>
		</div>
	);
};

export default SignupWithReferral;
