import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputBase, makeStyles } from '@material-ui/core';

const SearchInput = ({
	variant = 'outlined',
	margin,
	name,
	label,
	value,
	handleChange,
	placeholder = 'Search...',
	onKeyPress,
	autoFocus,
	...props
}) => {
	const classes = useStyles();

	return (
		<Box my={1}>
			<InputBase
				className={classes.inputBase}
				type="text"
				fullWidth
				name={name}
				variant={variant}
				margin={margin}
				autoFocus={autoFocus}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				autoComplete="off"
				onChange={handleChange}
				value={value}
			/>
		</Box>
	);
};

SearchInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

export default SearchInput;

export const useStyles = makeStyles((theme) => ({
	root: {},
	inputBase: {
		width: '100%',
		'& input, & textarea': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			borderRadius: 20,
			backgroundColor: theme.palette.common.input,
			padding: theme.spacing(1, 1.5),
			minHeight: 24,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: `1px solid ${theme.palette.secondary.main}`,
			fontSize: 15,
			'&:focus': {
				borderColor: theme.palette.common.white
			}
		}
	},
	label: {
		marginBottom: theme.spacing(1)
	},
	IconButton: {
		padding: '5px'
	},
	icon: {
		fontSize: '20px',
		color: '#888'
	}
}));
