import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

const ReferralScreen = ({ showTitle, children }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container maxWidth="xs">
				<Box>
					{showTitle && (
						<center className={classes.center}>
							<Typography variant="body1" className={classes.title} color="primary">
								You have been invited to join the Ellevate community. Sign up now and claim your 1 month
								free trial.
							</Typography>
						</center>
					)}

					{children}
				</Box>
			</Container>
		</div>
	);
};

export default ReferralScreen;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 30,
		left: 0,
		height: '100vh',
		width: '100vw',
		background:
			'linear-gradient(11deg, rgba(0, 0, 0, 0.30) 65.39%, rgba(107, 224, 160, 0.30) 98.36%), var(--paper, #000)',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 20
	},
	center: {
		marginBottom: 20
	},
	title: {
		fontFamily: 'Proximanova Medium',
		fontSize: '20px',
		fontWeight: '600',
		lineHeight: '24px',
		letterSpacing: '0em',
		textAlign: 'center'
	}
}));
