import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts, useLoaders } from 'hooks';
import api from 'api';
import { AppContext } from 'context';
import { uniq } from 'lodash';

const useAuth = (props) => {
	const history = useNavigate();

	const { showLoading, hideLoading } = useLoaders();

	const { showAlertError, showAlertSuccess } = useAlerts();

	const { authenticated, setAuthenticated, currentUser, setCurrentUser, setToken } = useContext(AppContext);

	const [user, setUser] = useState({});

	const handleChange = (ev) => {
		const { name } = ev.target;
		let value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
		setUser({
			...user,
			[name]: value
		});
	};

	const fetchMe = async () => {
		try {
			let resp = await api.get('/api/v1/users/me');
			console.log(resp);
			if (resp && resp.data) {
				setCurrentUser(resp.data);
			}
			return resp.data;
		} catch (e) {
			console.log(e);
			if (e?.status === 401) {
				showAlertError('Please sign in to continue.');
				localStorage.removeItem('token');
				window.location.href = '/';
			}
		}
	};

	const updateMe = async (user) => {
		showLoading();
		let resp = await api.put(`/api/v1/users/${user.id}`, {
			user: user
		});
		setCurrentUser(resp.data);
		hideLoading();
		return resp.data;
	};

	const loginUser = async (user) => {
		try {
			showLoading();
			let resp = await api.post('api/v1/login', {
				user: {
					email: user.email,
					password: user.password
				}
			});
			if (resp.data) {
				console.log(resp.data);
				await setTokens(resp.data);
				setCurrentUser(resp.data);
				hideLoading();
				window.location.href = '/home';
				return resp.data;
			} else {
				hideLoading();
				return false;
			}
		} catch (e) {
			showAlertError(e.data.message);
			hideLoading();
		}
	};

	const markViewedNotification = async (screenId) => {
		let viewedNotifications = uniq([...currentUser?.viewed_notifications, screenId]);
		await updateMe({
			id: currentUser.id,
			viewed_notifications: viewedNotifications
		});
	};

	const signupUser = async (user) => {
		try {
			showLoading();
			let resp = await api.post('/api/v1/signup', {
				user: user
			});
			console.log(resp);
			if (resp.data) {
				await setTokens(resp.data);
				// setCurrentUser(resp.data)
				hideLoading();
				return resp.data;
			} else {
				console.log(resp.data);
				hideLoading();
				return false;
			}
		} catch (e) {
			console.log(e);
			showAlertError(e.data.message);
			hideLoading();
		}
	};

	const resetPassword = async (password, passwordConfirmation) => {
		try {
			showLoading();
			let resp = await api.post('/api/v1/reset_password', {
				user: {
					password: password,
					password_confirmation: passwordConfirmation
				}
			});
			hideLoading();
			if (resp.data) {
				setCurrentUser(resp.data);
				setAuthenticated(true);
				setTokens(resp.data);
				return resp.data;
			} else {
				return false;
			}
		} catch (e) {
			showAlertError('There was an error resetting your password');
			hideLoading();
		}
	};

	const verifyPin = async (pin, email) => {
		let verified = false;
		try {
			showLoading();
			let resp = await api.post('/api/v1/verify_pin', {
				user: {
					pin,
					email
				}
			});
			if (resp.data?.id) {
				setCurrentUser(resp.data);
				setAuthenticated(true);
				setTokens(resp.data);
				return resp.data;
			} else {
				return false;
			}
			hideLoading();
			return verified;
		} catch (e) {
			hideLoading();
			return verified;
		}
	};

	const forgotPassword = async (email) => {
		try {
			showLoading();
			let resp = await api.post('/api/v1/forgot_password', {
				user: {
					email
				}
			});
			setCurrentUser(resp.data);
			hideLoading();
			return resp.data;
		} catch (e) {
			showAlertError('A user with that email could not be found');
			hideLoading();
		}
	};

	const logoutUser = () => {
		setCurrentUser(null);
		setAuthenticated(null);
		localStorage.removeItem('token');
		window.location.href = '/';
	};

	const authenticateFromToken = (token) => {
		if (token) {
			setAuthenticated(true);
			setToken(token);
		} else {
			return false;
		}
	};

	const setTokens = (user) => {
		setToken(user.token);
		localStorage.setItem('token', user.token);
	};

	useEffect(() => {
		if (currentUser && !authenticated) {
			setTokens(currentUser);
			setAuthenticated(true);
		}
	}, [currentUser]);

	const useQueryParams = (query) => {
		const result = {};

		console.log(window.location.href);
		if (window.location.href.includes('?')) {
			new URLSearchParams(query || window.location.search).forEach((value, key) => {
				result[key] = value;
			});
			console.log(result.token);
			setToken(result.token);
			localStorage.setItem('token', result.token);
			setAuthenticated(true);
			window.location.href = '/home';
		}
		return result;
	};

	return {
		useQueryParams,
		user,
		currentUser,
		handleChange,
		fetchMe,
		updateMe,
		loginUser,
		signupUser,
		logoutUser,
		verifyPin,
		resetPassword,
		forgotPassword,
		markViewedNotification,
		authenticateFromToken
	};
};

export default useAuth;
