import React from 'react';
import { Avatar } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const UserAvatar = ({ avatar, size = 40 }) => {
	const classes = useStyles({ size });
	return (
		<Avatar className={classes.avatar} size={size} src={avatar}>
			<Person />
		</Avatar>
	);
};

export default UserAvatar;

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: (props) => props.size,
		height: (props) => props.size,
		backgroundColor: theme.palette.primary.main
	}
}));
