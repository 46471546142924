import React from 'react';
import { CoverImage } from 'components';
import { makeStyles } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import { useResponsive } from 'hooks';

const VideoCover = ({ mobileImageUrl, desktopImageUrl, handleClick, ...props }) => {
	const classes = useStyles();
	const { isMobile } = useResponsive();

	return (
		<div className={classes.root}>
			<CoverImage
				mobileImageUrl={mobileImageUrl}
				desktopImageUrl={desktopImageUrl}
				icon={PlayArrow}
				onClick={handleClick}
			/>
		</div>
	);
};

export default VideoCover;

const useStyles = makeStyles((theme) => ({
	root: {}
}));
