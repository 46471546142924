import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'context';
import { useAlerts } from 'hooks';
import {
	Avatar,
	Fade,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Paper,
	Slide,
	Box,
	Typography
} from '@material-ui/core';
import { Clear, Cancel, Info } from '@material-ui/icons';

const Alert = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const { alert, setAlert } = useContext(AppContext);

	const handleClose = () => {
		setOpen(false);
		setAlert();
	};

	useEffect(() => {
		if (alert && alert?.message) {
			setOpen(true);
		}
	}, [alert]);

	useEffect(() => {
		if (open) setTimeout(() => setOpen(false), 2500);
	}, [open]);

	return (
		<Fade in={open}>
			<Slide direction="down" in={open}>
				<Box width="100%" p={2} className={classes.root}>
					<Paper elevation={4} className={classes.alert}>
						<List disablePadding>
							<ListItem button onClick={handleClose}>
								<ListItemIcon>
									<Info className={classes.icon} />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography variant="body1" className={classes.text}>
											{alert?.message || ''}
										</Typography>
									}
								/>
							</ListItem>
							<ListItemSecondaryAction>
								<IconButton size="small" onClick={handleClose}>
									<Clear className={classes.icon} />
								</IconButton>
							</ListItemSecondaryAction>
						</List>
					</Paper>
				</Box>
			</Slide>
		</Fade>
	);
};

export default Alert;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 2000,
		width: '100%',
		top: 0,
		left: 0
	},
	alert: {
		maxWidth: 600,
		margin: '0 auto',
		backgroundColor: theme.palette.error.neutral,
		color: theme.palette.common.white
	},
	text: {
		color: theme.palette.common.white
	},
	icon: {
		color: theme.palette.common.white
	}
}));
