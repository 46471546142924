import React from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { COVER_HEIGHT, COVER_WIDTH } from 'lib/constants';

import { BrokenImageOutlined } from '@material-ui/icons';
const NoImage = ({ large }) => {
	const classes = useStyles();

	return (
		<div className={large ? classes.large : classes.root}>
			<BrokenImageOutlined fontSize="large" className={large ? classes.icon : null} />
			<Typography variant={large ? 'h1' : 'h3'} color="primary" className={classes.subtitle}>
				No image available
			</Typography>
		</div>
	);
};

export default NoImage;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '80%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	large: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
		height: '87vh',
		width: '85vw'
	},
	icon: {
		transform: 'scale(4)'
	}
}));
