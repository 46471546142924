import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';

const SwitchInput = ({ value, label, name, handleChange }) => {
	const classes = useStyles();
	return (
		<FormGroup row>
			<FormControlLabel
				control={
					<Switch
						checked={Boolean(value)}
						onChange={handleChange}
						name={name}
						color="secondary"
						className={classes.switch}
					/>
				}
				label={<Typography variant="body1">{label}</Typography>}
			/>
		</FormGroup>
	);
};

export default SwitchInput;

const useStyles = makeStyles((theme) => ({
	switch: {
		'& .MuiSwitch-track': {
			backgroundColor: theme.palette.common.grey
		}
	}
}));
