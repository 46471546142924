import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const Panel = ({ p = 3, children, ...props }) => {
	const classes = useStyles();

	return (
		<Box p={p} className={classes.root}>
			{children}
		</Box>
	);
};
export default Panel;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		margin: theme.spacing(1, 0),
		borderRadius: 8,
		backgroundColor: theme.palette.background.light
	}
}));
