import React, { useContext, useState } from 'react';
import { useAlerts, useAuth } from 'hooks';
import { AppContext } from 'context';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import { AuthScreen, Subheader, TextInput, PinInput } from 'components';
import { ChevronRight } from '@material-ui/icons';
import queryString from 'query-string';

const ResetPassword = ({ ...props }) => {
	const location = useLocation();
	const params = queryString.parse(location.search);

	const [pin, setPin] = useState();

	const { showAlertError, showAlertSuccess } = useAlerts();

	const { authenticated, currentUser } = useContext(AppContext);

	const { verifyPin } = useAuth();

	const history = useNavigate();

	const handleSubmit = async () => {
		try {
			let resp = await verifyPin(pin, params.email);
			if (resp) {
				showAlertSuccess('Your pin is verified');
				window.location.href = '/reset_password';
			} else {
				showAlertError('pin is not valid');
			}
		} catch (e) {
			showAlertError("A user with that pin doesn't exist");
		}
	};

	const handleChange = ({ target }) => setPin(target.value);

	return (
		<div>
			<AuthScreen title="Enter Pin">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PinInput fullWidth required={true} value={pin} name="pin" handleChange={handleChange} />
					</Grid>
					<Grid item xs={12}>
						<center>
							<Box width={244}>
								<Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
									Next
								</Button>
							</Box>
						</center>
					</Grid>
				</Grid>
				<Box my={4} />
				<center>
					<Typography variant="body2">
						<Link color="secondary" component={RouterLink} to="/login">
							Sign in
						</Link>
					</Typography>
				</center>
			</AuthScreen>
		</div>
	);
};

export default ResetPassword;
