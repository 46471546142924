import { hasComponentName } from 'lib/utils';
import React from 'react';

const withStyles =
	(WrappedComponent) =>
	({ border = false, borderBottom = false, label, variant = 'base', ...rest }) => {
		const name = hasComponentName(WrappedComponent.name) ? WrappedComponent.name : WrappedComponent.displayName;

		const classes = useClasses(name, border, borderBottom, label, variant);

		return <WrappedComponent label={label} classes={classes} {...rest} />;
	};

const useClasses = (name, border, borderBottom, label, variant) => {
	const styles = getStyles(name, variant);
	const { useStyles } = styles;
	let styleObject = {};

	switch (name) {
		case 'TextInput':
			styleObject = {
				borderTop: border,
				borderBottom: borderBottom
			};
			break;
		case 'Autosuggest':
			styleObject = {
				flex: label ? true : false
			};
			break;
		case 'MaskInput':
			styleObject = { border, borderBottom };
	}

	return useStyles(styleObject);
};

const getStyles = (name, variant) => {
	switch (variant) {
		case 'referral':
			if (name === 'TextInput' || name === 'MaskInput')
				return require('../components/inputs/TextInput/styles-referral');
			if (name === 'Autosuggest') return require('../components/Autosuggest/styles-referral');
		case 'base':
			if (name === 'TextInput' || name === 'MaskInput')
				return require('../components/inputs/TextInput/styles-base');
			if (name === 'Autosuggest') return require('../components/Autosuggest/styles-base');
		default:
			return require('../components/inputs/TextInput/styles-base');
	}
};

export default withStyles;
