import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: (props) => props.flex && 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		borderTop: (props) => props.border == true && `1px solid ${theme.palette.primary.light}`
	},
	iconSelected: {
		height: 20,
		width: 20
	},
	label: {
		minWidth: 160
	},
	inputBase: {
		height: 44,
		maxWidth: 160,
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			padding: theme.spacing(1, 1.5, 1, 1.5),
			borderRadius: 0,
			backgroundColor: 'transparent',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 15,
			'&:focus': {
				borderColor: theme.palette.primary.main
			}
		}
	},
	paper: {
		backgroundColor: theme.palette.background.main,
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontSize: 15
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: theme.spacing(1, 1.5),
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent'
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover
		}
	},
	popperDisablePortal: {
		position: 'relative'
	},
	label: {
		width: 160,
		paddingLeft: theme.spacing(1.5)
	},
	text: {
		flexGrow: 1
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	}
}));

const useStylesReferral = makeStyles((theme) => ({
	root: {
		display: (props) => props.flex && 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		borderTop: (props) => props.border == true && `1px solid ${theme.palette.primary.light}`
	},
	iconSelected: {
		height: 20,
		width: 20
	},
	label: {
		minWidth: 160
	},
	inputBase: {
		height: 44,
		maxWidth: 160,
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			padding: theme.spacing(1, 1.5, 1, 1.5),
			borderRadius: 0,
			backgroundColor: 'transparent',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 15,
			'&:focus': {
				borderColor: theme.palette.primary.main
			}
		}
	},
	paper: {
		backgroundColor: theme.palette.background.main,
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontSize: 15
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: theme.spacing(1, 1.5),
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent'
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover
		}
	},
	popperDisablePortal: {
		position: 'relative'
	},
	label: {
		width: 160,
		paddingLeft: theme.spacing(1.5)
	},
	text: {
		flexGrow: 1
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	}
}));
