import { AuthScreen } from 'components';
import LoginForm from 'features/auth/LoginForm';
import { useAuth } from 'hooks';
import React from 'react';

const Login = () => {
	const { user, handleChange, loginUser } = useAuth();

	const handleSubmit = async () => {
		await loginUser(user);
	};

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit();
	};

	return (
		<div>
			<AuthScreen title="Welcome to Ellevate Football" open={false} alertMessage="" handleClosePopUp={null}>
				<LoginForm
					user={user}
					handleChange={handleChange}
					handleKeyPress={handleKeyPress}
					handleSubmit={handleSubmit}
				/>
			</AuthScreen>
		</div>
	);
};

export default Login;
