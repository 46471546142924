export const NODE_ENV = process.env.NODE_ENV;
export const ENABLE_ARTICLES = process.env.REACT_APP_ENABLE_ARTICLES;
export const ENABLE_EVENTS = process.env.REACT_APP_ENABLE_EVENTS;

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
export const CLOUDINARY_PRESET = process.env.REACT_APP_CLOUDINARY_PRESET;

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const VERIFF_API_KEY = process.env.REACT_APP_VERIFF_API_KEY;

export const VIDEO_COMPLETED_PCT = 0.75;
export const VIDEO_ENDED_PCT = 0.95;

export const INTRO_VIDEO_URL =
	'https://res.cloudinary.com/ellevate-football/video/upload/c_fill,w_400/v1631541749/assets/before-you-get-started.mp4';
export const INTRO_IMAGE_URL =
	'https://res.cloudinary.com/ellevate-football/image/upload/v1634258027/assets/premium-signup.jpg';

export const PREMIUM_SIGNUP_IMAGE_URL =
	'https://res.cloudinary.com/ellevate-football/image/upload/v1634258027/assets/premium-signup.jpg';

export const COVER_HEIGHT = 480;
export const COVER_MOBILE_HEIGHT = 280;
export const VIDEO_MOBILE_HEIGHT = 600;

export const THUMBNAIL_HEIGHT = 240;
export const THUMBNAIL_WIDTH_X3 = 400;
export const THUMBNAIL_WIDTH_X4 = 300;

export const SKELETON_HEIGHT = 180;
export const SKELETON_WIDTH = 320;

export const COURSE_THUMBNAIL_WIDTH_X3 = 320;
export const COURSE_THUMBNAIL_HEIGHT = 180;

export const VIDEO_WIDTH = 800;

export const VIDEO_GALLERY_WIDTH = 160;
export const VIDEO_GALLERY_HEIGHT = 160;

export const PROGRESS_VIDEOS = {
	control: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627055987/assets/control.mp4',
	defending: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627056006/assets/defending.mp4',
	dribbling: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627056003/assets/dribbling.mp4',
	passing: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627056009/assets/passing.mp4',
	shooting: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627056007/assets/shooting.mp4',
	star: 'https://res.cloudinary.com/ellevate-football/video/upload/v1627055999/assets/star.mp4'
};

export const COURSE_FILTERS = {
	POSITION: [
		{ value: null, label: 'All' },
		{ value: 'center_back', label: 'Centre Back' },
		{ value: 'full_back', label: 'Full Back' },
		{ value: 'midfielder', label: 'Midfielder' },
		{ value: 'winger', label: 'Winger' },
		{ value: 'striker', label: 'Striker' }
	],
	SKILL: [
		{ value: null, label: 'All' },
		{ value: 'control', label: 'Control' },
		{ value: 'passing', label: 'Passing' },
		{ value: 'dribbling', label: 'Dribbling' },
		{ value: 'shooting', label: 'Shooting' },
		{ value: 'defending', label: 'Defending' },
		{ value: 'star', label: 'Star Quality' }
	]
};

export const USER_ROLES = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'user', label: 'User' }
];

export const DIFFICULTY = [
	{ value: 'beginner', label: 'Beginner' },
	{ value: 'intermediate', label: 'Intermediate' },
	{ value: 'advanced', label: 'Advanced' },
	{ value: 'professional', label: 'Professional' }
];

export const POSITIONS = [
	// goalkeeper
	{ value: 'GK', label: 'Goalkeeper' },
	// defender
	{ value: 'LB', label: 'Left Back' },
	{ value: 'CB', label: 'Centre Back' },
	{ value: 'RB', label: 'Right Back' },
	{ value: 'LWB', label: 'Left Wing Back' },
	{ value: 'RWB', label: 'Right Wing Back' },
	// midfielder
	{ value: 'CDM', label: 'Defensive Midfielder' },
	{ value: 'CM', label: 'Centre Midfielder' },
	{ value: 'CAM', label: 'Attacking Midfielder' },
	{ value: 'LM', label: 'Left Midfielder' },
	{ value: 'RM', label: 'Right Midfielder' },
	// forward
	{ value: 'LW', label: 'Left Winger' },
	{ value: 'RW', label: 'Right Winger' },
	{ value: 'CF', label: 'Centre Forward' },
	{ value: 'ST', label: 'Striker' }
];

export const CATEGORIES = [
	// positions
	{ value: 'center_back', label: 'Centre Back' },
	{ value: 'full_back', label: 'Full Back' },
	{ value: 'midfielder', label: 'Midfielder' },
	{ value: 'winger', label: 'Winger' },
	{ value: 'striker', label: 'Striker' },
	// skills
	{ value: 'control', label: 'Ball Control' },
	{ value: 'passing', label: 'Passing' },
	{ value: 'dribbling', label: 'Dribbling' },
	{ value: 'shooting', label: 'Shooting' },
	{ value: 'defending', label: 'Defending' },
	{ value: 'star', label: 'Star Quality' }
];

export const WHAT_YOU_NEED = [
	{ value: '1_player', label: '1 Player' },
	{ value: '2_players', label: '2 Players' },
	{ value: '3_players', label: '3 Players' },
	{ value: '4_players', label: '4 Players' },
	{ value: '5_players', label: '5 Players' },
	{ value: 'cone', label: 'Cone' },
	{ value: 'goal', label: 'Goal' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'football', label: 'Football' },
	{ value: 'mannequin', label: 'Mannequin' },
	{ value: 'poles', label: 'Poles' }
];

export const WHAT_YOU_NEED_ICONS = {
	'1_player': '1 Player',
	'2_players': '2 Players',
	'3_players': '3 Players',
	'4_players': '4 Players',
	'5_players': '5 Players',
	phone: 'Phone',
	football: 'Football',
	cone: 'Cone',
	goal: 'Goal',
	mannequin: 'Mannequin',
	poles: 'Poles'
};

export const PREFERRED_FOOT = [
	{ value: 'left', label: 'Left' },
	{ value: 'right', label: 'Right' }
];

export const SKILLS = [
	{ value: 'control', label: 'Control' },
	{ value: 'defending', label: 'Defending' },
	{ value: 'dribbling', label: 'Dribbling' },
	{ value: 'passing', label: 'Passing' },
	{ value: 'shooting', label: 'Shooting' },
	{ value: 'star', label: 'Star Quality' }
];

export const FILTER_POSITIONS = [
	{ value: 'center_back', label: 'Centre Back' },
	{ value: 'full_back', label: 'Full Back' },
	{ value: 'midfielder', label: 'Midfielder' },
	{ value: 'winger', label: 'Winger' },
	{ value: 'striker', label: 'Striker' }
];

export const MEMBERSHIP_PRICE = '£4.99 / month';
export const MEMBERSHIP_ANNUAL_PRICE = '£49.99 / year';
export const INDONESIA_PRICE = '28,500 Rp / month';

export const PREMIUM_BENEFITS = [
	'Professional courses every month',
	'Regular, focused training plans',
	'Expert insights & editorial content',
	'Exclusive rewards & discounts',
	'Raise your profile & visibility',
	'Invitations to scouting days'
];
