import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const MobileCarousel = ({ label, children, navButtonsAlwaysInvisible = true, ...props }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<center className={classes.subtitle}>
				<Typography variant="h3">{label}</Typography>
			</center>
			<Carousel
				autoPlay={false}
				NextIcon={<ChevronRight className={classes.leftIcon} />}
				PrevIcon={<ChevronLeft className={classes.rightIcon} />}
				navButtonsWrapperProps={styles.navButtonsWrapper}
				navButtonsProps={styles.navButtons}
				navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
				animation={'slide'}
			>
				{children}
			</Carousel>
		</div>
	);
};

export default MobileCarousel;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexScale: 1
	},
	subtitle: {
		marginTop: 20,
		marginBottom: 20
	},
	leftIcon: {
		height: 44,
		width: 44
	},
	rightIcon: {
		height: 44,
		width: 44
	}
}));

const styles = {
	navButtonsWrapper: {
		style: {
			bottom: '80px',
			top: 'unset'
		}
	},
	navButtons: {
		style: {
			backgroundColor: 'transparent'
		}
	}
};
