import React, { useContext, useEffect } from 'react';
import {
	Avatar,
	Hidden,
	Typography,
	Container,
	Grid,
	Link,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	makeStyles,
	Button
} from '@material-ui/core';
import { AppContext } from 'context';
import { Modal, CoverImage } from 'components';
import { Check } from '@material-ui/icons';
import {
	INDONESIA_PRICE,
	MEMBERSHIP_PRICE,
	MEMBERSHIP_ANNUAL_PRICE,
	PREMIUM_BENEFITS,
	PREMIUM_SIGNUP_IMAGE_URL
} from 'lib/constants';
import StripeCreditCard from './StripeCreditCard';
import { useResponsive } from 'hooks';
import { useState } from 'react';
import SubscriptionImage from '../../assets/images/subscription-background.png';
import { isPaid } from 'lib/utils';
import { useNavigate } from 'react-router';

const PremiumSignup = ({ open, onSubscribed, ...rest }) => {
	const classes = useStyles();
	const history = useNavigate();

	const { currentUser } = useContext(AppContext);
	const { isMobile } = useResponsive();
	const [selectedPackage, setSelectedPackage] = useState('Annual');
	const { setSelectedPackageName } = useContext(AppContext);
	const addPaymentPackage = (packageName) => {
		setSelectedPackage(packageName);
		setSelectedPackageName(packageName);
	};
	useEffect(() => {
		if (isPaid(currentUser)) {
			history.push('/users/StripeSubscription');
		}
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.coverImage}>
				<CoverImage disableGradient mobileImageUrl={SubscriptionImage} desktopImageUrl={SubscriptionImage} />
				<div className={classes.gradient} />
			</div>

			<div className={classes.content}>
				<Container maxWidth={'lg'}>
					<div className={isMobile ? classes.columnContainer : classes.rowContainer}>
						{/* benfits column */}
						<div className={classes.columnContainer}>
							<div className={classes.pageTitleContainer}>
								<Typography variant="h2" className={classes.title}>
									Ellevate Your Game
								</Typography>
							</div>
							<div className={isMobile ? classes.columnContainer : classes.bottomContainer}>
								<div className={classes.benefits}>
									{PREMIUM_BENEFITS.map((text, i) => (
										<div key={i} className={classes.listContainer}>
											<List disablegutters="true">
												<ListItem className={classes.listItem}>
													<ListItemIcon>
														<Avatar variant="circular" className={classes.avatar}>
															<Check className={classes.icon} />
														</Avatar>
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography className={classes.subtitle} variant="body2">
																{text}
															</Typography>
														}
													/>
												</ListItem>
											</List>
										</div>
									))}
								</div>
							</div>
						</div>

						{/* subscribtion packages Container */}

						<div className={classes.columnContainer}>
							<div className={isMobile ? classes.fullWidth : classes.subscribeContainer}>
								<div
									className={
										selectedPackage === 'Monthly'
											? classes.subscribeContent
											: classes.unSelectedSubscribeContent
									}
									onClick={() => addPaymentPackage('Monthly')}
								>
									<Typography variant="h3" className={classes.title}>
										MONTHLY <Hidden smDown> SUBSCRIPTION </Hidden>
									</Typography>
									<div className={classes.priceContainer}>
										<Typography variant="body1" color="secondary">
											{currentUser?.country == 'ID' ? (
												<span>{INDONESIA_PRICE}</span>
											) : (
												<span>{MEMBERSHIP_PRICE}</span>
											)}
										</Typography>
										<Typography variant="body1" color="secondary">
											+7 Day Free Trial
										</Typography>
									</div>
								</div>

								<div
									className={
										selectedPackage === 'Annual'
											? classes.subscribeContent
											: classes.unSelectedSubscribeContent
									}
									onClick={() => addPaymentPackage('Annual')}
								>
									<div className={classes.discount}>17 % discount</div>
									<div className={classes.rowContainer}>
										<Typography variant="h3" className={classes.title}>
											ANNUAL
											<Hidden smDown> SUBSCRIPTION</Hidden>
										</Typography>
									</div>
									<div className={classes.priceContainer}>
										<Typography variant="body1" color="secondary">
											{currentUser?.country == 'ID' ? (
												<span>{MEMBERSHIP_ANNUAL_PRICE}</span>
											) : (
												<span>{MEMBERSHIP_ANNUAL_PRICE}</span>
											)}
										</Typography>
										<Typography variant="body1" color="secondary">
											+14 Day Free Trial
										</Typography>
									</div>
								</div>
							</div>
							<div style={{ height: 20 }} />
							<StripeCreditCard onSubscribed={onSubscribed} selectedPackage={selectedPackage} />
						</div>
					</div>
				</Container>
			</div>
			<div className={classes.footer}>
				<Container maxWidth="md">
					<center>
						<Typography variant="body2" className={classes.footerText}>
							Click here to view our{' '}
							<Link
								href="https://ellevate-football.com/terms-conditions/"
								target="_blank"
								className={classes.link}
							>
								Terms & Conditions
							</Link>{' '}
							/{' '}
							<Link
								href="https://ellevate-football.com/privacy-policy-2/"
								target="_blank"
								className={classes.link}
							>
								Privacy Policy
							</Link>
							.
						</Typography>
					</center>
				</Container>
			</div>
		</div>
	);
};

export default PremiumSignup;

const useStyles = makeStyles((theme) => ({
	root: {},
	coverImage: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 'auto'
	},
	content: {
		padding: theme.spacing(1),
		marginTop: -80,
		[theme.breakpoints.down('xs')]: {
			marginTop: -20
		}
	},
	title: {
		color: theme.palette.common.white
	},
	subtitle: {
		lineHeight: '1em'
	},
	listItem: {
		padding: 0,
		margin: 0
	},
	benefits: {
		marginTop: theme.spacing(3)
	},
	avatar: {
		height: 24,
		width: 24,
		backgroundColor: theme.palette.common.white
	},
	icon: {
		height: 20,
		width: 20,
		color: theme.palette.secondary.main
	},
	subscribeContainer: {
		borderRadius: 8,
		width: '100%'
	},
	unSelectedSubscribeContent: {
		position: 'relative',
		cursor: 'pointer',
		padding: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),

		marginTop: theme.spacing(2),
		borderRadius: 8,
		backgroundColor: theme.palette.background.light
	},
	subscribeContent: {
		position: 'relative',
		padding: theme.spacing(1),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),

		marginTop: theme.spacing(2),
		borderRadius: 8,
		border: `1px solid ${theme.palette.secondary.main}`,
		backgroundColor: theme.palette.background.light
	},
	footer: {
		marginTop: theme.spacing(10),
		padding: theme.spacing(2),
		paddingTop: theme.spacing(4),
		height: 200,
		backgroundColor: theme.palette.background.light
	},
	footerText: {
		color: '#999999'
	},
	link: {
		color: '#999999',
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	gradient: {
		marginTop: -350,
		height: 200,
		width: '100%',
		backgroundImage: 'linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%)'
	},
	rowContainer: {
		display: 'flex',
		gap: '20%'
	},
	columnContainer: {
		width: '100%'
	},
	submitButtonContainer: {
		width: '65%',
		paddingLeft: '12%'
	},
	fullWidth: {
		width: '100%'
	},
	priceContainer: {
		display: 'flex',
		gap: '50%'
	},
	listContainer: {
		direction: 'column',
		width: '100%'
	},
	bottomContainer: {
		display: 'flex',
		gap: '12%'
	},
	discount: {
		position: 'absolute',
		right: 10,
		top: -7,
		justifyContent: 'center',
		whiteSpace: 'nowrap',
		color: 'black',
		backgroundColor: theme.palette.secondary.main,
		borderRadius: 20,
		fontFamily: 'Proximanova Medium',
		fontSize: 10,
		lineHeight: 1,
		minWidth: 80,
		padding: '2px 8px 2px 8px',
		border: `1px solid ${theme.palette.secondary.main}`,
		alignContent: 'center',
		alignSelf: 'center'
	}
}));
