import React from 'react';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import { useResponsive } from 'hooks';
import { COVER_HEIGHT, COVER_MOBILE_HEIGHT } from 'lib/constants';
import clsx from 'clsx';

const CoverImage = ({
	desktopImageUrl,
	mobileImageUrl,
	icon: Icon,
	onClick,
	height = COVER_HEIGHT,
	opacity = 1.0,
	disableGradient = false,
	borderRadius = false,
	primaryLabel,
	secondaryLabel,
	secondaryAction
}) => {
	const { isMobile } = useResponsive();
	const src = isMobile ? mobileImageUrl : desktopImageUrl;

	const classes = useStyles({
		src,
		opacity,
		borderRadius
	});

	return (
		<div className={classes.root}>
			<div
				className={clsx(classes.cover, {
					[classes.gradient]: !disableGradient
				})}
			>
				<div className={classes.coverHeader}>
					<div>
						{primaryLabel && (
							<div className={classes.label}>
								<Typography variant="body2" className={classes.labelText}>
									{primaryLabel}
								</Typography>
							</div>
						)}
					</div>
					<div>
						{secondaryLabel && (
							<div className={classes.label}>
								<Typography variant="body2" className={classes.labelText}>
									{secondaryLabel}
								</Typography>
							</div>
						)}
						{secondaryAction && <div className={classes.secondaryAction}>{secondaryAction}</div>}
					</div>
				</div>
				{Icon && (
					<IconButton onClick={onClick}>
						<Icon className={classes.icon} />
					</IconButton>
				)}
				<div className={classes.coverFooter}></div>
			</div>
		</div>
	);
};

export default CoverImage;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 'auto'
	},
	coverHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	cover: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		width: '100%',
		borderRadius: (props) => (props.borderRadius ? theme.shape.borderRadius : 0),
		height: COVER_HEIGHT,
		backgroundPosition: '50% 25%',
		backgroundImage: (props) => `url(${props.src}) `,
		backgroundSize: 'cover',
		opacity: (props) => props.opacity,
		[theme.breakpoints.down('sm')]: {
			height: COVER_MOBILE_HEIGHT
		}
	},
	coverFooter: {
		padding: theme.spacing(2)
	},
	gradient: {
		maskImage: 'linear-gradient(to top, rgba(0,0,0,0.05) 0%,rgba(0,0,0,1.0) 15%)'
	},
	icon: {
		height: 90,
		width: 90,
		color: theme.palette.common.white
	},
	label: {
		position: 'absolute',
		zIndex: 999,
		padding: theme.spacing(0, 1),
		borderRadius: 20,
		margin: theme.spacing(2),
		backgroundColor: '#CDCECF',
		color: theme.palette.background.main
	},
	labelText: {
		color: theme.palette.background.main
	},
	secondaryAction: {
		padding: theme.spacing(0, 1),
		margin: theme.spacing(2)
	}
}));
