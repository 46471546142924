import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

const PaywallOverlay = ({ open, handleClick, label = 'Upgrade Now For Access', paddingTop = 260 }) => {
	const classes = useStyles({
		paddingTop: paddingTop
	});

	return open === true ? (
		<div className={classes.overlay}>
			<Button variant="contained" onClick={handleClick} startIcon={<Lock />} className={classes.button}>
				{label}
			</Button>
		</div>
	) : null;
};
export default PaywallOverlay;

const useStyles = makeStyles((theme) => ({
	overlay: {
		height: (props) => props.paddingTop,
		marginBottom: (props) => -props.paddingTop,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		zIndex: theme.zIndex.modal - 1
	}
}));
