import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import Image from 'assets/images/signup-success.png';
import { DownloadAppButton } from 'components';
import PropTypes from 'prop-types';

const SignupSuccess = ({ title }) => {
	const classes = useStyles({});
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<center>
					<Typography variant="body1">{title}</Typography>
				</center>
				<img src={Image} className={classes.image} />
				<center>
					<Typography variant="body1">Download the app and sign in to ellevate your game.</Typography>
				</center>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Box my={4} />
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						gap: '5%'
					}}
				>
					<DownloadAppButton variant={'apple'} />
					<DownloadAppButton variant={'google-play'} />
				</div>
				<Box my={4} />
			</Grid>
		</Grid>
	);
};

export default SignupSuccess;

SignupSuccess.propTypes = {
	title: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
	image: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '50vh',
			objectFit: 'contain'
		}
	}
}));
