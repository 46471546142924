import { Box, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import Image from 'assets/images/signup-success.png';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const UserForm = () => {
	const classes = useStyles({});

	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<img src={Image} className={classes.image} />
			</Grid>
			<Box my={4} />
			<Grid item xs={12} sm={12}>
				<center>
					<Typography variant="body1">Don't Have An Account?</Typography>
					<Typography variant="body1">
						<Link color="secondary" component={RouterLink} to="/signup">
							Sign Up
						</Link>
					</Typography>
				</center>
			</Grid>
		</Grid>
	);
};

export default UserForm;

const useStyles = makeStyles((theme) => ({
	image: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '50vh',
			objectFit: 'contain'
		}
	}
}));
