import React, { useContext } from 'react';
import { AppContext } from 'context';
import { LinearProgress, Backdrop, makeStyles } from '@material-ui/core';

const Loader = (props) => {
	const classes = useStyles();
	const { isLoading } = useContext(AppContext);
	return (
		<Backdrop className={classes.backdrop} open={isLoading}>
			<LinearProgress color="secondary" className={classes.linearProgress} />
		</Backdrop>
	);
};
export default Loader;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative'
	},
	linearProgress: {
		position: 'fixed',
		backgroundColor: 'transparent',
		top: 0,
		height: 4,
		width: '100vw',
		zIndex: 9999
	},
	backdrop: {
		opacity: 0,
		zIndex: 9999
	}
}));
