import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateUser } from 'validations/users';

const useUsers = (props) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: user,
		resources: users,
		findOne: findUser,
		findMany: findUsers,
		update: updateUser,
		create: createUser,
		save: saveUser,
		destroy: deleteUser,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadUser,
		reloadMany: reloadUsers,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setCurrentUser
	} = useResource({
		id: 'me',
		url: '/api/v1/users',
		name: 'user'
	});

	const enrollCourse = async (courseId) => {
		try {
			showLoading();
			const resp = await api.post(`/api/v1/users/${courseId}/enroll_course`);
			if (resp?.data?.id) {
				setCurrentUser(resp?.data);
			}
			hideLoading();
			return resp?.data;
		} catch (e) {
			hideLoading();
			showAlertError('There was an error enrolling in the course');
		}
	};

	const unenrollCourse = async (courseId) => {
		try {
			showLoading();
			const resp = await api.post(`/api/v1/users/${courseId}/unenroll_course`);
			if (resp?.data?.id) {
				setCurrentUser(resp?.data);
			}
			hideLoading();
			return resp?.data;
		} catch (e) {
			hideLoading();
			showAlertError('There was an error leaving the course');
		}
	};

	const completeLesson = async (lessonId) => {
		try {
			const resp = await api.post(`/api/v1/users/${lessonId}/complete_lesson`);
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error completing the lesson');
		}
	};

	const viewLesson = async (lessonId) => {
		try {
			const resp = await api.post(`/api/v1/users/${lessonId}/view_lesson`);
			if (resp?.data) {
				setCurrentUser(resp.data);
			}
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error viewing the lesson');
		}
	};

	const addPayment = async (stripeToken, selectedPackageName) => {
		showLoading();
		try {
			let resp = await api.post(`/api/v1/payments/add_payment`, {
				payment: {
					stripe_token: stripeToken
				}
			});
			if (resp?.data) {
				hideLoading();
				resp = await subscribe(selectedPackageName);
			}
			hideLoading();
			return resp?.data;
		} catch (e) {
			hideLoading();
			showAlertError('There was an error enrolling in the course');
		}
	};

	const removePayment = async () => {
		try {
			const resp = await api.post(`/api/v1/payments/remove_payment`);
			if (resp?.data) {
				setCurrentUser(resp.data);
			}
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error enrolling in the course');
		}
	};

	const subscribe = async (selectedPackageName) => {
		try {
			showLoading();
			const resp = await api.post(`/api/v1/payments/subscribe`, {
				stripe_plan: selectedPackageName.toLowerCase()
			});
			hideLoading();
			return resp?.data;
		} catch (e) {
			hideLoading();
			showAlertError('There was an error enrolling in the course');
		}
	};

	const unsubscribe = async (stripeToken) => {
		try {
			const resp = await api.post(`/api/v1/payments/unsubscribe`);
			if (resp?.data) {
				setCurrentUser(resp.data);
				window.location.href = '/users/premiumsignup';
			}
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error enrolling in the course');
		}
	};

	const findPublicUser = async (userId) => {
		try {
			const resp = await api.get(`/api/v1/users/${userId}/public`);
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error finding the user');
		}
	};

	const createVerification = async (userId) => {
		const resp = await api.get(`/api/v1/users/${userId}/create_verification`);
		return resp;
	};

	const requestParentApproval = async (userId, parent) => {
		try {
			const resp = await api.post(`/api/v1/users/${userId}/request_approval`, {
				first_name: parent.first_name,
				last_name: parent.last_name,
				email: parent.email
			});
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error updating the user');
		}
	};

	const parentApprove = async (userId) => {
		try {
			const resp = await api.post(`/api/v1/users/${userId}/parent_approved`);
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error updating the user');
		}
	};

	const parentUnapprove = async (userId) => {
		try {
			const resp = await api.post(`/api/v1/users/${userId}/parent_unapproved`);
			return resp?.data;
		} catch (e) {
			showAlertError('There was an error updating the user');
		}
	};

	return {
		userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		currentUser: user,
		user,
		users,
		findUser,
		findUsers,
		saveUser,
		updateUser,
		createUser,
		deleteUser,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,

		enrollCourse,
		unenrollCourse,
		completeLesson,
		viewLesson,

		subscribe,
		unsubscribe,
		addPayment,
		removePayment,

		findPublicUser,
		requestParentApproval,
		parentApprove,
		parentUnapprove,

		createVerification,

		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		reloadUser,
		reloadUsers,
		sortKey,
		sortDirection,
		handleSort
	};
};

export default useUsers;
