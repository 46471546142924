import React, { useState } from 'react';
import { useAlerts, useAuth } from 'hooks';
import { AuthScreen } from 'components';
import SignupForm from 'features/auth/SignupForm';
import { validateUser } from 'validations/users';
import moment from 'moment';
import SignupSuccess from 'features/auth/SignupSuccess';

const Signup = ({ ...props }) => {
	const { user, handleChange, signupUser, authenticateFromToken } = useAuth();
	const { showAlertError } = useAlerts();
	const [promoCodePopupMessage, setPromoCodePopupMessage] = React.useState();
	const [showPromoSuccessPopup, setShowPromoSuccessPopup] = React.useState(false);
	const [signUpCompleted, setSignUpCompleted] = useState(false);

	React.useEffect(() => {
		setShowPromoSuccessPopup(showPromoSuccessPopup);
	}, [showPromoSuccessPopup]);

	React.useEffect(() => {
		setPromoCodePopupMessage(promoCodePopupMessage);
	}, [promoCodePopupMessage]);

	const handleSubmit = async () => {
		const valid = validateUser(user);
		if (valid.isValid) {
			const resp = await signupUser({
				...user,
				dob: moment(user.dob, 'DD/MM/YYYY')
			});
			if (resp?.id && resp.promo_code_message) {
				// eslint-disable-next-line max-len
				setPromoCodePopupMessage(`${resp.promo_code_message}`);
				setShowPromoSuccessPopup(true);
			}

			authenticateFromToken(resp.token);
			setSignUpCompleted(true);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit();
	};

	return (
		<div>
			<AuthScreen
				title="Sign Up"
				open={showPromoSuccessPopup}
				handleClosePopUp={() => (window.location.href = '/home')}
				alertMessage={promoCodePopupMessage}
			>
				{signUpCompleted ? (
					<SignupSuccess title={'You have successfully signed up!'} />
				) : (
					<SignupForm
						user={user}
						handleChange={handleChange}
						handleKeyPress={handleKeyPress}
						handleSubmit={handleSubmit}
					/>
				)}
			</AuthScreen>
		</div>
	);
};

export default Signup;
