import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Button, LinearProgress, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const IMAGE_WIDTH = 240;

const ImageUploader = ({
	circle = false,
	handleUpload,
	label = 'Upload image',
	size = IMAGE_WIDTH,
	imageUrl,
	handleDelete,
	...props
}) => {
	const classes = useStyles({ size });
	const [isEditing, setIsEditing] = useState(false);
	const [file, setFile] = useState();
	const [files, setFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isUploaded, setIsUploaded] = useState(false);
	const [dropzoneActive, setDropzoneActive] = useState(false);

	const handleEditClick = () => setIsEditing(!isEditing);

	const onDrop = async (files) => {
		setIsUploading(true);
		setIsUploaded(false);
		await handleUpload(files[0]);
		setIsEditing(false);
		setIsUploading(false);
		setIsUploaded(true);
		setFiles([]);
		setFile(null);
	};

	const onDragEnter = (files) => {
		setFiles(files);
		setDropzoneActive(true);
	};

	const onDragLeave = (files) => {
		setFiles(files);
		setDropzoneActive(false);
	};

	return (
		<div>
			{imageUrl && !isEditing && (
				<img
					className={clsx(classes.image, {
						[classes.circle]: circle
					})}
					size={size}
					src={imageUrl}
				/>
			)}

			{(isEditing || !imageUrl) && (
				<Dropzone
					className={classes.dropZone}
					disableClick={false}
					accept="image/*"
					onDrop={onDrop}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					multiple={false}
				>
					{!isUploaded && !isUploading && !dropzoneActive && (
						<Typography variant="overline">{label}</Typography>
					)}

					{isUploaded && <Typography variant="overline">Upload complete</Typography>}

					{!isUploaded && !isUploading && dropzoneActive && (
						<Typography variant="overline">Drop image</Typography>
					)}

					{!isUploaded && isUploading && <CircularProgress color="primary" />}
				</Dropzone>
			)}

			{imageUrl && handleDelete && (
				<Box mb={2}>
					<Button className={classes.button} color="secondary" size="small" onClick={handleDelete}>
						Remove
					</Button>
				</Box>
			)}
		</div>
	);
};

export default ImageUploader;

const useStyles = makeStyles((theme) => ({
	image: {
		padding: 2,
		borderRadius: 4,
		marginRight: theme.spacing(1),
		width: (props) => props.size
	},
	circle: {
		borderRadius: 100
	},
	dropZone: {
		margin: 0,
		height: 140,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px dotted ${theme.palette.text.secondary}`,
		backgroundColor: theme.palette.background.paper,
		textAlign: 'center',
		marginBottom: theme.spacing(2)
	},
	button: {
		fontSize: 11,
		textTransform: 'uppercase'
	}
}));
