import React from 'react';
import { Routes, Route } from 'react-router-dom';

//auth
import ForgotPassword from 'features/auth/ForgotPassword';
import Login from 'features/auth/Login';
import ResetPassword from 'features/auth/ResetPassword';
import Signup from 'features/auth/Signup';
import VerifyPin from 'features/auth/VerifyPin';
import SignupWithReferral from 'features/referrals/Referral';

const Router = () => (
	<Routes>
		<Route path="/" element={<Login />} exact />
		<Route path="/login/:token" element={<Login />} />
		<Route path="/login" element={<Login />} />
		<Route path="/signup" element={<Signup />} />
		<Route path="/referral" element={<SignupWithReferral />} />
		<Route path="/forgot_password" element={<ForgotPassword />} exact />
		<Route path="/verify_pin" element={<VerifyPin />} exact />
		<Route path="/reset_password" element={<ResetPassword />} exact />
		<Route path="/users/passwords/edit" element={<ResetPassword />} exact />
	</Routes>
);

export default Router;
