import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

function rand() {
	return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();
	return {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	};
}
const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		bgcolor: 'background.paper',
		outline: 0
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		alignItems: 'center',
		justifyContent: 'center',
		border: `1px solid ${theme.palette.secondary.main}`,
		outline: 0
	},
	circleView: {
		width: 100,
		height: 100,
		borderRadius: 100,
		border: `1px solid ${theme.palette.secondary.main}`,
		borderWidth: 2,
		padding: 5,
		justifyContent: 'center',
		alignItems: 'center'
	},
	icon: {
		height: 80,
		width: 80,
		alignSelf: 'center'
	}
}));
export default function CustomModal({ open = false, buttonLabel, title, handleClose, msg }) {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				BackdropComponent={Backdrop}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<Box display="flex" justifyContent="center">
							<Typography marginbottom={10} variant={'h3'}>
								{title}
							</Typography>
						</Box>
						<Box display="flex" justifyContent="center" marginTop={5}>
							<Box className={classes.circleView}>
								<Check className={classes.icon} fontSize="large" />
							</Box>
						</Box>
						<Box display="flex" justifyContent="center" marginTop={5} marginLeft={5} marginRight={5}>
							<Typography variant="body1">{msg}</Typography>
						</Box>
						<center>
							<Box my={6}>
								<Button
									className={classes.button}
									color="primary"
									onClick={handleClose}
									variant="contained"
								>
									{buttonLabel}
								</Button>
							</Box>
						</center>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
