import React from 'react';
import { makeStyles } from '@material-ui/core';

const ImageList = ({ urls, width = 240, ...rest }) => {
	const classes = useStyles();
	return (
		<div className={classes.imageList}>
			{urls.map((url, i) => (
				<div key={i} className={classes.imageItem}>
					<img src={url} width={width} />
				</div>
			))}
		</div>
	);
};

export default ImageList;

const useStyles = makeStyles((theme) => ({
	imageList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		overflowY: 'hidden',
		overFlowX: 'scroll'
	},
	imageItem: {
		margin: 10
	}
}));
